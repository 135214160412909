export const sections = [
    {
        id: 'about',
        type: 'about'
    },
    {
        title: 'Experience',
        id: 'experience',
        type: 'experience'
    },
    {
        title: 'Videos',
        id: 'videos',
        type: 'videos'
    },
    {
        title: 'Blogs',
        id: 'blogs',
        type: 'blogs'
    }
]