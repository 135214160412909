// TODO : Need to update this file.
// Maybe - i will Add href from here.

export const navItems = [
    {
        title: 'About',
        href: '#about'
    },
    {
        title: 'Experience',
        href: '#experience'
    },
    {
        title: 'Videos',
        href: '#videos'
    },
    {
        title: 'Blogs',
        href: '#blogs'
    },

]